
<template>

    <div class="svg-loader" >
      <svg class="svg-container" height="120" width="120" viewBox="-10 0 120 100">
          <path  class="loader-svg small animate3" d="M0 75 A 50 50 90 0 1 100 75"  style="stroke:#0d35be" />
          <path class="loader-svg medium animate2" d="M25 75 A 25 25 90 0 1 100 75"  style="stroke: #112eda"  />
          <path  class="loader-svg big animate" d="M25 75 A 10 10 90 0 1 81 75"  style="stroke:#068ffd" />
      </svg>
  </div>

</template>
<script>
export default {
  
}
</script>
<style scoped >
  
    .svg-loader{
    display:flex;
    position: relative;
    align-content: space-around;
    justify-content: center;
  }
  .loader-svg{
    position: absolute;
    left: 0; right: 0; top: 0; bottom: 0;
    fill: none;
    stroke-width: 8px;
    stroke-linecap:butt;
  }
  .loader-svg.bg{
    stroke-width: 8px;
    stroke: rgb(207, 205, 245);
  }
    
  .animate{
    stroke-dasharray: 87.9;
    stroke-dashoffset: 87.9;
    animation: dash1 2s infinite;
  }
    
   .animate2{
    stroke-dasharray: 117.8;
    stroke-dashoffset: 117.8;
    animation: dash2 3s 1S infinite;
  }

  .animate3{
    stroke-dasharray: 157.1;
    stroke-dashoffset: 157.1;
    animation: dash3 2S 2s infinite;
  }

  @keyframes dash1 {
  0%{
    stroke-dashoffset: -87.0;
  }
  50%{
    stroke-dashoffset: 0;
  }
  100%{
    stroke-dashoffset: -87.0;
  }
  }


  @keyframes dash2 {
  0%{
    stroke-dashoffset: 117.8;
  }
  50%{
    stroke-dashoffset: 0;
  }
  100%{
    stroke-dashoffset: 117.8;
  }

  }

  @keyframes dash3 {
  0%{
    stroke-dashoffset: -157.1;
  }
  50%{
    stroke-dashoffset: 0;
  }
  100%{
    stroke-dashoffset: -157.1;
  }
}
  

</style>



