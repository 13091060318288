<template>
  <b-container>
    <div class="text-center my-5">
      <MTBLoader v-if="items.length === 0" />
    </div>

    <h2 class="mt-5 text-primary">{{ this.$props.categoryTitle}}</h2>

    <b-row class="justify-content-md-center">
      <b-col class="text-center" md="10">
        <h5 class="mb-5 text-muted">{{categorySubtitle}}</h5>
      </b-col>
    </b-row>

    <!-- CARDS GRID -->
  <b-row>
    <b-col>
       <div class="my-grid">
            <b-card
              v-for="(report, index) in reports"
              :key="index"
              img-width="250"
              fluid-grow
              class="cardUnlocked"
              text-variant="white"
              style="border-radius:8px;"
              :img-src="report.cover"
              img-alt="Image"
              overlay
              @click="goToReport(report)"
              >
              <div class="card-content">

              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>

<b-row class="mt-3 mb-5"> 
  <b-col>
    <b-button class="mt-3 mx-2" size="sm" :to="{ name: 'ReportCategory', params: { name: categoryRoute } }" pill variant="outline-primary"><v-icon class="mx-2"  name="search"  scale="1" />More Reports</b-button>
  </b-col>
</b-row>

 



  </b-container>
</template>

<script>
import MTBLoader from "../components/MTBLoader";
import util from "../support/util";
export default {
  name: "ReportGridPreview",
  components: { MTBLoader },
  props: ["items", "category","subtitle","categoryTitle"],

  data() {
    return {

      };
  },
  computed: {
    reports() {
      return this.$props.items;
    },
    reports() {
      return this.$props.items.filter((report)=>{
        return !report.draft
      })
    },
    categoryRoute(){
      return this.$props.category
    },

    categorySubtitle(){
      return this.$props.subtitle
    }
  },
  created() {
    this.noImage = util.noImage;
    this.loadLogo = util.loadLogo;
    this.scrollTop = util.scrollTop;
  },
  methods: {
    goToReport(report){
      this.$router.push({ name: 'Report', params: { name: report.slug } })
    },

  },
};
</script>

<style scoped>
.my-grid {
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.5rem;
}
.my-grid > * {
  width: 100%;
  max-width: 20rem;
}
.card:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}
.card {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.card-img{
  border-radius:8px;
}
</style>

