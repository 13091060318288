<template>
  <b-container>

    <div class="text-center my-5">
      <MTBLoader v-if="items.length === 0" />
    </div>

    <!-- <h1 class="text-primary mb-4">Investigating Innovation Ecosystems</h1> -->
    <!-- <h3 class="muted">Research Reports</h3> -->

    
          <!-- MAIN DIRECTORIES LARGE -->
          <div  v-if="items.length !== 0">
            <div v-for="(item, index) in directories" :key="index" id="banner-image">
              <b-row class="my-5">
                <b-col>
                  <b-img class="shadow-sm" style="min-height:38vh; border-radius: 25px; filter: brightness(0.8);" fluid-grow :src="item['banner']"></b-img>
                  <div class="title-banner">
                    <h1 style="font-size: calc(10px + 2.3vw);">{{ item["title"].split("-")[0] }}</h1>
                    <b-button class="mt-3 mx-2" size="sm" :to="{ name: 'Directory', params: { name: item.slug } }"  pill variant="primary"><v-icon class="mr-2"  name="search"  scale="1" />Explore Directory</b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <h3>Want to stay up to date? <a href="http://axcd.clientmailup.com/frontend/forms/Subscription.aspx?idList=1&idForm=36&guid=e26b0135-c66f-4fdd-ac33-46565f258167" target="_blank"> Subscribe to our Newsletter</a></h3>
            <h5> <a  href="http://mindthebridge.com/" target="_blank" > Visit our website</a> for more info</h5>

          </div>
  </b-container>
</template>

<script>
import MTBLoader from "../components/MTBLoader";
import ReportsGridPreview from "../components/ReportsGridPreview";

import util from "../support/util";

export default {
  data() {
    return {};
  },
  components: { MTBLoader,ReportsGridPreview },
  created(){
    util.scrollTop()
    this.$store.dispatch("loadReports").catch((e) => {
      console.log(e);
    });
      this.$store.dispatch("loadDirectories").catch((e) => {
      console.log(e);
    });
  },
  computed: {
    items() {
      return this.$store.state.reports;
    },
    currentReports(){
      return this.$store.state.reports.filter((item)=>{
        return item.current && !item.main
      })
    },
    mainReports(){
      return this.$store.state.reports.filter((item)=>{
        return item.main
      })
    },
    // directories(){
    //   return this.$store.state.directories
    // },
    directories(){
      return this.$store.state.directories.filter((directory)=>{return directory.show})
    },
    scaleupsCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "scaleups"
      })
    },
    scaleupsCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "scaleups"
      })
    },
    policyCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "policy"
      })
    },
    outpostsCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "outposts"
      })
    },
    openinnovationCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "open-innovation"
      })
    },
    maipoCategory(){
      return this.$store.state.reports.filter((item)=>{
        return item.category == "ma-ipo"
      })
    },
  },
};
</script>

<style scoped>
.title-banner {
  z-index: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.title-banner-small {
  z-index: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

#banner-image:hover {
  -webkit-transform: scale(1.02, 1.02);
  transform: scale(1.02, 1.02);
}

#banner-image{
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  min-height: 300px;
}
#banner-image::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
</style>
